//
//
//
//
//
//
//
//

export default {
  name: 'ExportEventsImageArea',
  data() {
    return {};
  },
  props: {
    selectedImages: {
      type: Array,
      required: true,
    },
    selectedImage: {
      type: Number,
      required: true,
    },
  },
  components: {},
  methods: {},
  watch: {},
  computed: {},
  mounted() {},
};
